$base-color: #00733A;
$main-color: #20ABAD;
$omi-color: #eb592d;
$common-padding: 10px;
$white-color: #ffffff;
$text-color: #3f3b3b;
$border-color: #767474;
$light-border-color: #e0e0e0;
$s-select-green: rgba(1, 153, 73, 1)

